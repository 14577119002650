<template>
  <div>
    <base-module
      :moduleOptions="options"
      title="Clientes"
      ref="module"
      :loadOnInit="false"
    >
      <template v-slot:documentsformfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <h3>{{ slotProps.full_name }}</h3>
          <div>
            <strong>C.C.</strong>
            {{ slotProps.identity_number | numericFormat }}
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">email:</span> {{ slotProps.email }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Teléfono:</span> {{ slotProps.phone }}
            </div>
          </div>
          <div v-if="slotProps.enterprise">
            <span class="font-semibold">Empresa:</span>
            {{ slotProps.enterprise.name }}
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Ocupación:</span>
              {{ slotProps.occupation }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Salario:</span> ${{
                slotProps.salary | numericFormat
              }}
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Dirección:</span>
              {{ slotProps.address }}
            </div>
            <div v-if="slotProps.city !== undefined" class="flex-1">
              <span class="font-semibold">Ciudad:</span>
              {{ slotProps.city.name }}
            </div>
          </div>
        </div>
      </template>
    </base-module>
    <client-form :handlePostSubmit="handlePostSubmit" :record="selectedRow" />
    <process-lawyer-form
      :handlePostSubmit="handlePostSubmitProcess"
      :idModal="idModal"
      :record="selectedRow"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import BaseModule from "@/components/Base/BaseModule";
import ClientForm from "./ClientForm";
import ProcessLawyerForm from "../Processlawyer/ProcessLawyerForm.vue";
import { deserialize } from "jsonapi-fractal";
import { mapGetters } from "vuex";
import {
  maximunToListId,
  profileIdToList,
  minimumSalaryToListId
} from "../../config/parameters.js";
export default {
  inject: ["clientsRepository", "conceptsRepository"],

  components: {
    BaseModule,
    ClientForm,
    ProcessLawyerForm
  },

  computed: {
    ...mapGetters("user", ["getUser"])
  },

  data() {
    const me = this;
    return {
      options: {
        columns: [
          {
            header: "Cédula",
            prop: "identity_number"
          },
          {
            header: "Nombre Completo",
            prop: "full_name"
          },
          {
            header: "Celular",
            prop: "phone"
          },
          {
            header: "Salario",
            prop: "salary"
          },
          {
            header: "Empresa",
            prop: "enterprise.name"
          },
          {
            header: "Ciudad",
            prop: "city.name"
          }
        ],
        repository: {
          name: "clientsRepository"
        },
        sortable: ["identity_number", "full_name"],
        selectedRowIndexToShow: "full_name",
        primary: "id",
        templates: {
          identity_number(h, row) {
            return `${me.$options.filters.numericFormat(row.identity_number)}`;
          },
          salary(h, row) {
            return `$${me.$options.filters.numericFormat(row.salary)}`;
          }
        }
      },

      selectedRow: null,
      idModal: "modal-process-lawyer-form",
      disabled: true
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    },

    handlePostSubmitProcess() {
      this.$router.push({
        path: "processlawyer"
      });
    }
  },

  async mounted() {
    const me = this;

    let concepts = await me.conceptsRepository.get();
    let result = await me.clientsRepository.get();
    let clients = deserialize(result.data);

    me.concepts = deserialize(concepts.data);

    if (me.getUser.profile_id === profileIdToList) {
      me.$refs.module.records = clients.filter(
        res =>
          res.salary <=
          me.concepts.find(item => item.id === maximunToListId).value
      );
    } else {
      me.$refs.module.records = clients;
    }

    me.minimumSalary = me.concepts.find(
      item => item.id === minimumSalaryToListId
    ).value;
  }
};
</script>

<style lang="scss" scoped></style>
